import {navigate} from 'gatsby-link';
import React from 'react';
import Button from '../../button';
import picture from '../../../images/tools/screenshot-capture/one.png';
import picture1 from '../../../images/tools/screenshot-capture/two.png';

const Info = () => (
  <div className="accessibility-info row mt-0 mx-0 mb-3">
    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Preview your landing pages across different browser device sizes </h4>
      <div className="mb-2">
        Catch rendering issues before they affect your business by testing your landing pages in up to 8 different
        device screen sizes including: Small, medium, large mobile devices, tablet, small, medium, large laptop devices,
        and 4K desktop screen sizes.
      </div>
      <div className="mb-4">
        Generate full webpage snapshots in seconds from the cloud with our screenshot generator.
      </div>

      <div>
        <Button className="px-0" onClick={() => navigate('/registration')}>
          Sign up for Hexomatic account to increase your limits
        </Button>
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture} alt="Grammar &amp; spelling page audit" />
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 change-order-3">
      <img src={picture1} alt="Grammar &amp; spelling page audit" />
    </div>
    <div className="col-12 col-md-6 mb-5">
      <h4 className="mb-4">Perform comprehensive multi-device screenshot audits of entire websites with Hexomatic</h4>
      <div className="mb-2">
        Upgrade to a paid Hexomatic plan to access our sitewide screenshot automation for the ultimate peace of mind.
      </div>
      <div className="mb-4">
        Hexomatic is a unique work automation platform that enables you to tap into 100+ ready-made automations to
        generate multi-device snapshots, spelling, grammar or accessibility audits in minutes and delegate
        time-consuming sales, marketing, or research tasks.
      </div>
      <div>
        <Button onClick={() => navigate('/tools/')}>Click here to find out more</Button>
      </div>
    </div>
  </div>
);

export default Info;
