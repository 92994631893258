import React from 'react';
import FAQItem from '../../../components/FAQ-item';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import ScreenshotCaptureWrapper from '../../../components/tools-components/screenshot-capture';

const ScreenshotCapture = () => (
  <Layout>
    <PageHero
      title="Capture website screenshots for any device screen size online"
      titleSmall
      subtitle="Generate full-page website screenshots for a wide range of device screen sizes to preview how your pages look and test for responsive browser issues."
      className="toolPage"
      id='seo_home_area_tools'
    />
    <Section className="m-auto w-100">
      <ScreenshotCaptureWrapper />
    </Section>
    <Section>
      <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
        <h4 className="mb-4 mt-4 text-center">Screenshot Capture FAQ</h4>
        <FAQItem
          title="What is a screenshot audit of a website?"
          text={
            <p>
              A multi-device screenshot audit allows checking your website for any responsive browser issues in seconds.
              Our Hexomatic screen capture tool can preview landing pages using 7 device screen sizes, including small
              medium, and large mobile devices, tablet, small, medium, and large laptop devices, and 4K desktop screen
              sizes.
            </p>
          }
        />
        <FAQItem
          title="How to take a full-page screenshot?"
          text={
            <p>
              Do you need to take a full-page screenshot of your web page but don’t want to scroll it or use a
              drag-and-snap option? You are in the right place. Using the Hexomatic screen capture tool, you can easily
              capture the whole web page by adding its URL and choosing your device type.
            </p>
          }
        />
        <FAQItem
          title="What are the capabilities of the screenshot tool?"
          text={
            <p>
              Our screenshot capture tool enables you to perform screenshot audits on a large number of devices in
              seconds. After taking the screenshot, it can be then downloaded to your device for further analysis.
            </p>
          }
        />
        <FAQItem
          title="What is the advantage of screenshot testing on real devices?"
          text={
            <>
              <p>
                First and foremost, screenshot testing on real devices ensures that the given website appears properly
                without any visual issues that can cause user experience problems.
              </p>
              <p>
                The captured image shows what exactly the end users see when the website loads on a specific device, be
                it a laptop or mobile device.
              </p>
            </>
          }
        />
        <FAQItem
          title="How do I take a screenshot of a whole web page without scrolling?"
          text={
            <p>
              Our Hexomatic screen capture tool enables getting your web page captured without any scrolling. Just add
              your webpage URL and our tool will return the screenshot. Additionally, you can set the delay time in
              seconds for taking your screenshot.
            </p>
          }
        />
        <FAQItem
          title="Which factors should you consider while selecting the best screen capture tool?"
          text={
            <>
              <p>
                Different screen capture tools prioritize different features. Before choosing the best tool, you should
                understand your needs and requirements. You should confirm that the features of the tool meet all your
                requirements. For example, if you want to use a screen capture tool for testing your landing page, then
                you need to use a tool that is capable of doing a full-page screenshot.
              </p>
              <p>
                Price is another important component to consider. Before subscribing to a specific tool, make sure to
                conduct a market analysis and find the best tool in the category with the best price. Our screen capture
                tool is ideal for full-page screenshots. It has a free version + you can increase your limits by signing
                up for Hexomatic account.
              </p>
            </>
          }
        />
      </div>
    </Section>{' '}
  </Layout>
);

export default ScreenshotCapture;
